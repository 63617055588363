/* Orders.css */
.orders-container {
    padding: 20px;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow-x: auto;
  }
  
  .orders-table th, .orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .orders-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
    color: black;
  }
  
  .orders-table input[type="checkbox"] {
    margin: 0; /* Reset default margins */
    cursor: pointer;
    width: 20px; /* Ensure checkboxes are adequately sized */
    height: 20px;
  }
  
  /* Media Queries for Responsiveness */
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .orders-table th, .orders-table td {
      font-size: 16px;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    .orders-table th, .orders-table td {
      font-size: 14px;
      padding: 6px;
    }
  }
  
  /* Small devices (phones, less than 768px) */
  @media (max-width: 767px) {
    .orders-container {
      padding: 10px;
    }
  
    .orders-table {
      margin-top: 10px;
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .orders-table th, .orders-table td {
      display: block;
      font-size: 12px;
      padding: 4px;
      white-space: normal;
    }
  
    .orders-table th {
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: right;
    }
  
    .orders-table td {
      text-align: left;
      padding-left: 50%;
      position: relative;
    }
  
    .orders-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 4px;
      font-weight: bold;
      text-align: left;
    }
  
    .orders-table th,
    .orders-table td[data-label="Images"] {
      text-align: left;
    }
  }