.order-notes-container {
  background: linear-gradient(135deg, #f0f4ff, #e4eaf8);
  padding: 60px 20px;
  text-align: center;
}

.order-notes {
  background: #ffffff;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  max-width: 700px;
  margin: 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.order-notes.expanded {
  transform: scale(1.02);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.notes-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: color 0.3s ease;
}

.notes-header:hover {
  color: #593EA1;
}

.notes-icon {
  font-size: 1.8rem;
  color: #593EA1;
}

.accordion-arrow {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.accordion-arrow.expanded {
  transform: rotate(180deg);
}

.notes-content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.notes-content.expanded {
  max-height: 500px;
  opacity: 1;
}

.order-notes h2 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
}

.order-notes ul {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.order-notes li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 12px;
  padding-left: 20px;
  position: relative;
}

.order-notes li .note-icon {
  font-size: 1.4rem;
  color: #593EA1;
  margin-right: 10px;
}

.order-notes li:hover {
  background: #f7f8fc;
  border-radius: 12px;
  padding: 10px 20px;
  transition: background 0.3s ease, padding 0.3s ease;
}
