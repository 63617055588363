.header-box {
    min-height: 70vh;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #FFFFFF;
}

.app-bar {
    background: transparent !important;
    box-shadow: none !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
    transition: background 0.3s ease;
}

.app-bar.scrolled {
    background: rgba(255, 255, 255, 0.903) !important;
    height: 70px;
}

.toolbar {
    height: 100px;
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 200px;
    max-height: 200px;
}

.link-button {
    font-size: 1.25rem;
    margin: 0 8px;
    transition: color 0.3s ease;
    color: white;
}

.link-button.scrolled {
    color: #593EA1;
}

.link-button.drawer-link {
    color: #593EA1;
}

.link-button:hover {
    color: #593EA1;
}

.hero-text {
    color: white;
    font-family: 'Tangerine', cursive;
    font-size: 5rem;
}

/* .hero-text:hover {
    color: #593EA1;
} */

.hero-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(90vh - 0px);
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    padding: 0 16px;
}

.drawer-paper {
    width: 150px;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawer-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-top: 20px;
    gap: 20px;
}

.drawer-list-item {
    transition: color 0.3s ease, transform 0.3s ease;
    width: 130px;
    height: 50px;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 10px;
    border: 0px solid #F3D7D7;
    color: #593EA1;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
    background: transparent;
}

.drawer-list-item:hover {
    color: #ffffff;
    transform: translateX(-10px);
    background-color: #593EA1;
    border-color: #fff;
}

.drawer-list-item > .MuiListItemText-root {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media (max-width: 600px) {
    .link-button {
        font-size: 1rem;
        margin: 0 4px;
    }
    .hero-text {
        font-size: 3rem;
    }
}
