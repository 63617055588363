.product-page {
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.order-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #593EA1;
}

.product-selection {
  text-align: center;
  padding: 30px;
}

.packages {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.package-card {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.package-card:hover {
  transform: scale(1.05);
}

.package-bg {
  background-image: url('../Imgs/magnet4.jpg');
  background-size: cover;
  background-position: center;
  padding: 30px;
  border-radius: 15px;
  color: white;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.package-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  z-index: 1;
}

.package-bg h2,
.package-bg p {
  position: relative;
  z-index: 2;
}

.order-form {
  background: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.order-form h2 {
  margin-top: 0;
}

.order-form label {
  display: block;
  margin: 15px 0 5px;
}

.order-form input {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.order-form button {
  background: #593EA1;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s;
}

.order-form button:hover {
  background: #3e278a;
}

.image-previews {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.image-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.product-page {
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.order-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #593EA1;
}

.image-previews {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.image-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}