/* Default styles */
.promo-section {
    background-image: url('../Imgs/magnet7.jpg'); /* Update the path */
    background-size: cover;
    background-attachment: fixed;
    height: 60vh; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: rgb(0, 0, 0);
    text-align: center;
    background-position: center; /* Ensure the image is centered */
    background-repeat: no-repeat; /* Prevent image repetition */
}

.promo-content {
    z-index: 2; /* Ensure it appears above the background */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Adjust as needed for spacing */
}

.order-button {
    background-color: #f39c12;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.order-button i {
    font-size: 20px;
}

.whatsapp-button {
    background-color: #25d366;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none; /* Ensure it's a button style */
}

.whatsapp-button i {
    font-size: 20px;
}

.promo-text {
    background-color: rgba(0, 0, 0, 0.3)!important; /* Semi-transparent dark background */
    color: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
    .promo-section {
        background-attachment: scroll; /* Change background attachment for better scrolling performance on mobile */
        height: 400px; /* Adjust height for mobile */
        padding: 20px; /* Add padding to the section */
        text-align: left; /* Adjust text alignment for mobile */
        background-position: center; /* Ensure the image is centered */
    }

    .promo-text {
        font-size: 18px; /* Decrease font size for mobile */
    }

    .order-button, .whatsapp-button {
        font-size: 14px; /* Decrease font size for mobile */
        padding: 8px 16px; /* Adjust padding for mobile */
    }
}